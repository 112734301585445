import React from "react";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";

export default function Terms() {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <a href="/landing">
                  <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <i className="fas fa-home text-xl"></i>
                  </div>
                </a>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Terms and Conditions
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
Last updated February 22nd, 2021
</p>
<h5>AGREEMENT TO TERMS</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
These Terms and Conditions constitute a legally binding agreement made between you, 
whether personally or on behalf of an entity (“you”) and nextCoder, owner of DW Digest, dwdigest.com, and all of its subdomains (“we,” “us” or “our”), 
concerning your access to and use of the dwdigest.com website as well as any other media form, 
media channel, web application, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”).

You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions. 
If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue use immediately.

Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. 
We reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at any time and for any reason.

We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions, 
and you waive any right to receive specific notice of each such change.

It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. 
You will be subject to, and will be deemed to have been made aware of and to have accepted, 
the changes in any revised Terms and Conditions by your continued use of the Site after the date such revised Terms and Conditions are posted.

The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction 
or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.

Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible 
for compliance with local laws, if and to the extent local laws are applicable.

The Site is intended for users who are at least 18 years old. Persons under the age of 18 must have the permission of, 
and be directly supervised by, their parent or guardian to use the Site.

</p>
<h5>INTELLECTUAL PROPERTY RIGHTS</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, 
video, text, screenshots, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, 
and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and 
trademark laws and various other intellectual property rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions.

The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. 
Except as expressly provided in these Terms and Conditions, no part of the Site and no Content or Marks may be copied, 
reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, 
sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.

Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download 
or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. 
We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.

</p>
<h5>USER REPRESENTATIONS</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
By using the Site, you represent and warrant that:

(1) all registration information you submit will be true, accurate, current, and complete; 

(2) you will maintain the accuracy of such information and promptly update such registration information as necessary;

(3) you have the legal capacity and you agree to comply with these Terms and Conditions;

(4) you are not under the age of 13;

(5) not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission and supervision to use the Site;

(6) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;

(7) you will not use the Site for any illegal or unauthorized purpose;

(8) your use of the Site will not violate any applicable law or regulation.

If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or 
terminate your account and refuse any and all current or future use of the Site (or any portion thereof).

</p>
<h5>PROHIBITED ACTIVITIES</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

You may not access or use the Site for any purpose other than that for which we make the Site available. 
The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.

As a user of the Site, you agree not to:

    systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.
    make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.
    use a buying agent or purchasing agent to make purchases on the Site.
    use the Site to advertise or offer to sell goods and services.
    circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.
    engage in unauthorized framing of or linking to the Site.
    trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;
    make improper use of our support services or submit false reports of abuse or misconduct.
    engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.
    interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.
    attempt to impersonate another user or person or use the username of another user.
    sell or otherwise transfer your profile.
    use any information obtained from the Site in order to harass, abuse, or harm another person.
    use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.
    decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.
    attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.
    harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.
    delete the copyright or other proprietary rights notice from any Content.
    copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
    upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.
    upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).
    except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.
    disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.
    use the Site in a manner inconsistent with any applicable laws or regulations.

</p>
<h5>USER OWNED DATA</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

Users of this Site can be our client or not. If you are not our client, the usage of any data coming from you (your browser, phone, internet provider) 
are governed within our Privacy Policy agreement.

If you are our client, by using this Site, you will guarantee that any data pushed or pulled into or processed or analysed by this Site 
are legally yours or that you have legally binding contract that allows you to access it.

This data applies to anything that relates to the functionality of this Site.

We do not assert any ownership over your data. You retain full ownership of all of your data and any intellectual property rights or other 
proprietary rights associated with your data. We are not liable for any statements or representations in your data provided by you in any area on the Site.

You are solely responsible for your data as it relates to the Site and you expressly agree to exonerate us from any and all 
responsibility and to refrain from any legal action against us regarding your data.

We have the right, within the context of our agreement with you, (1) to store, sort, query, aggregate any data; 
(2) to re-categorize any data to place them in more appropriate locations on the Site.

We have no obligation to ensure, guarantee, or monitor the accuracy of your data that is pulled into the Site. This is also specified in our agreement with you as our client.

</p>
<h5>MOBILE APPLICATION LICENSE</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

Use License

If you access the Site via a mobile application, then we grant you a revocable, non-exclusive, non-transferable, 
limited right to install and use the mobile application on wireless electronic devices owned or controlled by you, 
and to access and use the mobile application on such devices strictly in accordance with the terms and conditions 
of this mobile application license contained in these Terms and Conditions.

You shall not:

(1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application;

(2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the application;

(3) violate any applicable laws, rules, or regulations in connection with your access or use of the application;

(4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the application;

(5) use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended;

(6) make the application available over a network or other environment permitting access or use by multiple devices or users at the same time;

(7) use the application for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the application;

(8) use the application to send automated queries to any website or to send any unsolicited commercial e-mail;

(9) use any proprietary information or any of our interfaces or our other intellectual property in the design, 
development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the application.

{/* Apple and Android Devices

The following terms apply when you use a mobile application obtained from either the Apple Store or Google Play (each an “App Distributor”) to access the Site:

(1) the license granted to you for our mobile application is limited to a non-transferable license to use the application 
on a device that utilizes the Apple iOS or Android operating systems, as applicable, 
and in accordance with the usage rules set forth in the applicable App Distributor’s terms and conditions;

(2) we are responsible for providing any maintenance and support services with respect to the mobile application 
as specified in the terms and conditions of this mobile application license contained in these Terms and Conditions 
or as otherwise required under applicable law, and you acknowledge that each App Distributor has no obligation whatsoever 
to furnish any maintenance and support services with respect to the mobile application;

(3) in the event of any failure of the mobile application to conform to any applicable warranty, you may notify the applicable App Distributor, 
and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the mobile application, 
and to the maximum extent permitted by applicable law, the App Distributor will have no other warranty obligation whatsoever with respect to the mobile application;

(4) you represent and warrant that (i) you are not located in a country that is subject to a U.S. government embargo, 
or that has been designated by the U.S. government as a “terrorist supporting” country and 
(ii) you are not listed on any U.S. government list of prohibited or restricted parties;

(5) you must comply with applicable third-party terms of agreement when using the mobile application, 
e.g., if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the mobile application;

6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and conditions in 
this mobile application license contained in these Terms and Conditions, and that each App Distributor will have the 
right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile application license 
contained in these Terms and Conditions against you as a third-party beneficiary thereof. */}

</p>
<h5>THIRD-PARTY ACCOUNTS</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

As part of the functionality of the Site, you may link your account with online accounts you have with third-party service providers 
(each such account, a “Third-Party Account”) by either: (1) providing your Third-Party Account login information through the Site; 
or (2) allowing us to access your Third-Party Account, as is permitted under the applicable terms and conditions that govern your use of each Third-Party Account.

You represent and warrant that you are entitled to disclose your Third-Party Account login information to us and/or grant 
us access to your Third-Party Account, without breach by you of any of the terms and conditions that govern your use of the applicable Third-Party Account, 
and without obligating us to pay any fees or making us subject to any usage limitations imposed by the third-party service provider of the Third-Party Account.

By granting us access to any Third-Party Accounts, you understand that (1) we may access, make available, and store (if applicable) 
any content that you have provided to and stored in your Third-Party Account (the “Third-party Account Content”) so that it is available 
on and through the Site via your account, including without limitation any friend lists and (2) we may submit to and receive from 
your Third-Party Account additional information to the extent you are notified when you link your account with the Third-Party Account.

Depending on the Third-Party Accounts you choose and subject to the privacy settings that you have set in such Third-Party Accounts, 
personally identifiable information that you post to your Third-Party Accounts may be available on and through your account on the Site.

Please note that if a Third-Party Account or associated service becomes unavailable or our access to such Third-Party Account 
is terminated by the third-party service provider, then Third-party Account Content may no longer be available on and through the Site. 
You will have the ability to disable the connection between your account on the Site and your Third-Party Accounts at any time.

PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED 
SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.

We make no effort to review any Third-party Account Content for any purpose, including but not limited to, for accuracy, legality, 
or non-infringement, and we are not responsible for any Third-party Account Content.

You acknowledge and agree that we may access your email address book associated with a Third-Party Account and your contacts 
list stored on your mobile device or tablet computer solely for purposes of identifying and informing you of those contacts who have also registered to use the Site.

You can deactivate the connection between the Site and your Third-Party Account by contacting us using the contact information 
below or through your account settings (if applicable). We will attempt to delete any information stored on our servers that was 
obtained through such Third-Party Account, except the username and profile picture that become associated with your account.

</p>
<h5>THIRD-PARTY WEBSITES AND CONTENT</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

The Site may contain (or you may be sent via the Site) links to other websites (“Third-Party Websites”) as well as articles, 
photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or 
items belonging to or originating from third parties (“Third-Party Content”).

Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, 
or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Site or any Third-Party Content posted on, 
available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, 
or other policies of or contained in the Third-Party Websites or the Third-Party Content.

Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval 
or endorsement thereof by us. If you decide to leave the Site and access the Third-Party Websites or to use or install any Third-Party Content, 
you do so at your own risk, and you should be aware these Terms and Conditions no longer govern.

You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate 
from the Site or relating to any applications you use or install from the Site. Any purchases you make through Third-Party Websites will be 
through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which 
are exclusively between you and the applicable third party.

You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us harmless 
from any harm caused by your purchase of such products or services. Additionally, you shall hold us harmless from any losses sustained by 
you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.

</p>
<h5>SITE MANAGEMENT</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

We reserve the right, but not the obligation, to:

(1) monitor the Site for violations of these Terms and Conditions;

(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms and Conditions, 
including without limitation, reporting such user to law enforcement authorities;

(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, 
or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;

(4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable 
all files and content that are excessive in size or are in any way burdensome to our systems;

(5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.

</p>
<h5>PRIVACY POLICY</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

We care about data privacy and security. Please review our Privacy Policy. By using the Site, you agree to be bound by our Privacy Policy, 
which is incorporated into these Terms and Conditions. Please be advised the Site is hosted in the United States.

If you access the Site from the European Union, Asia, or any other region of the world with laws or other requirements 
governing personal data collection, use, or disclosure that differ from applicable laws in the United States, 
then through your continued use of the Site, you are transferring your data to the United States, 
and you expressly consent to have your data transferred to and processed in the United States.

Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. 
Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that 
anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, 
we will delete that information from the Site as quickly as is reasonably practical.

</p>
<h5>DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

Notifications

We respect the intellectual property rights of others. If you believe that any material available on or through the 
Site infringes upon any copyright you own or control, please immediately notify our Designated Copyright Agent using 
the contact information provided below (a “Notification”).

A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. 
Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification. 
Thus, if you are not sure that material located on or linked to by the Site infringes your copyright, you should consider first contacting an attorney.

All Notifications should meet the requirements of DMCA 17 U.S.C. § 512(c)(3) and include the following information:

(1) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;

(2) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on the Site are covered by the Notification, 
a representative list of such works on the Site;

(3) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, 
and information reasonably sufficient to permit us to locate the material;

(4) information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, 
an email address at which the complaining party may be contacted;

(5) a statement that the complaining party has a good faith belief that use of the material in the manner complained of 
is not authorized by the copyright owner, its agent, or the law;

(6) a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is 
authorized to act on behalf of the owner of an exclusive right that is allegedly infringed upon.

</p>
<h5>TERM AND TERMINATION</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

These Terms and Conditions shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, 
WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), 
TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN 
THESE TERMS AND CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE IN THE SITE OR DELETE YOUR ACCOUNT AND
ANY CONTENT OR INFORMATION THAT YOU GENERATED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.

If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, 
a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.

In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, 
including without limitation pursuing civil, criminal, and injunctive redress.

</p>
<h5>MODIFICATIONS AND INTERRUPTIONS</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. 
However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time.

We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.

We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to 
perform maintenance related to the Site, resulting in interruptions, delays, or errors.

We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. 
You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site.

Nothing in these Terms and Conditions will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.

</p>
<h5>GOVERNING LAW</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

These Terms and Conditions and your use of the Site are governed by and construed in accordance with the laws of the State of Texas 
applicable to agreements made and to be entirely performed within the State/Commonwealth of Texas, without regard to its conflict of law principles.

</p>
<h5>DISPUTE RESOLUTION</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

Option 1: Any legal action of whatever nature brought by either you or us (collectively, the “Parties” and individually, a “Party”) shall 
be commenced or prosecuted in the state and federal courts located in Collin County, Texas, and the Parties hereby consent to, 
and waive all defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in such state and federal courts.

Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information 
Transaction Act (UCITA) are excluded from these Terms and Conditions. In no event shall any claim, action, or proceeding brought 
by either Party related in any way to the Site be commenced more than 3 years after the cause of action arose.

Option 2: Informal Negotiations

To expedite resolution and control the cost of any dispute, controversy, or claim related to these Terms and Conditions 
(each a “Dispute” and collectively, the “Disputes”) brought by either you or us (individually, a “Party” and collectively, the “Parties”), 
the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least 60 days before initiating arbitration. 
Such informal negotiations commence upon written notice from one Party to the other Party.

</p>
<h6>Binding Arbitration</h6>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except those Disputes expressly excluded below) 
will be finally and exclusively resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.

The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American Arbitration Association (“AAA”) and, 
where appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes (“AAA Consumer Rules”), both of which are available at the AAA website www.adr.org.

Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA Consumer Rules and, where appropriate, 
limited by the AAA Consumer Rules. [If such costs are determined by the arbitrator to be excessive, we will pay all arbitration fees and expenses.]

The arbitration may be conducted in person, through the submission of documents, by phone, or online. The arbitrator will make a decision in writing, 
but need not provide a statement of reasons unless requested by either Party.

The arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so. 
Except where otherwise required by the applicable AAA rules or applicable law, the arbitration will take place in Collin County, Texas.

Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay proceedings pending arbitration, 
or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.

If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be commenced or prosecuted in the state and 
federal courts located in Collin County, Texas, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction, 
and forum non conveniens with respect to venue and jurisdiction in such state and federal courts.

Application of the United Nations Convention on Contracts for the International Sale of Goods and the the Uniform Computer Information Transaction Act (UCITA) 
are excluded from these Terms and Conditions.

In no event shall any Dispute brought by either Party related in any way to the Site be commenced more than 3 years after the cause of action arose. 
If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this 
provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, 
and the Parties agree to submit to the personal jurisdiction of that court.

</p>
<h6>Option 3: Binding Arbitration</h6>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

To expedite resolution and control the cost of any dispute, controversy or claim related to these Terms and Conditions (each a “Dispute” and collectively, “Disputes”), 
any Dispute brought by either you or us (individually, a “Party” and collectively, the “Parties”) shall be finally and exclusively resolved by binding arbitration.

YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. 
The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American Arbitration Association (“AAA”) and, 
where appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes (“AAA Consumer Rules”), both of which are available at the AAA website www.adr.org.

Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. 

The arbitration may be conducted in person, through the submission of documents, by phone, or online. 
The arbitrator will make a decision in writing, but need not provide a statement of reasons unless requested by either Party. 
The arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so.

Except where otherwise required by the applicable AAA rules or applicable law, the arbitration will take place in Collin County, Texas. 
Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay proceedings pending arbitration, or to confirm, 
modify, vacate, or enter judgment on the award entered by the arbitrator.

If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be commenced or prosecuted in the state and federal courts located in 
Collin County, Texas, and the Parties hereby consent to, and waive all defenses of lack of, personal jurisdiction, and forum non conveniens with respect 
to venue and jurisdiction in such state and federal courts.

Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) 
are excluded from these Terms and Conditions. In no event shall any Dispute brought by either Party related in any way to the Site or Services be commenced 
more than 3 years after the cause of action arose.

If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this 
provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, 
and the Parties agree to submit to the personal jurisdiction of that court.

</p>
<h6>Option 2: Restrictions</h6>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. 
To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; 
(b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and 
(c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.

</p>
<h6>Option 3: Exceptions to [Informal Negotiations and] Arbitration</h6>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

The Parties agree that the following Disputes are not subject to the above provisions concerning [informal negotiations and] binding arbitration: 
(a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; 
(b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and 
(c) any claim for injunctive relief.

If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion 
of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the 
courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.

</p>
<h5>CORRECTIONS</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to the Site, 
including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, 
or omissions and to change or update the information on the Site at any time, without prior notice.

</p>
<h5>DISCLAIMER</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. 
TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, 
INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. 
WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED 
TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, 
(2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, 
(3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, 
(4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, 
(5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR 
(6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, 
TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT 
OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED 
IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN 
YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.

AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.

</p>
<h5>LIMITATIONS OF LIABILITY</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, 
CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, 
OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.]

</p>
<h5>INDEMNIFICATION</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, 
agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ 
fees and expenses, made by any third party due to or arising out of: (1) use of the Site; (2) breach of these Terms and Conditions; 
(3) any breach of your representations and warranties set forth in these Terms and Conditions; 
(4) your violation of the rights of a third party, including but not limited to intellectual property rights; or 
(5) any overt harmful act toward any other user of the Site with whom you connected via the Site.

Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter 
for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. 
We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.

</p>
<h5>USER DATA</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

We will maintain certain data that you transmit to the Site for the purpose of managing the Site, as well as data relating to your use of the Site. 
Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site.

You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us 
arising from any such loss or corruption of such data.

</p>
<h5>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, 
and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, 
satisfy any legal requirement that such communication be in writing.

YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, 
AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.

You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original 
signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.

</p>
<h5>TEXAS USERS AND RESIDENTS</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of 
the Texas Attorney General - Consumer Protection in writing at PO Box 12548 Austin, TX 78711-2548 or by telephone at Consumer Protection Hotline at 1-800-621-0508.

</p>
<h5>MISCELLANEOUS</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

These Terms and Conditions and any policies or operating rules posted by us on the Site constitute the entire agreement and understanding between you and us. 
Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or provision.

These Terms and Conditions operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. 
We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.

If any provision or part of a provision of these Terms and Conditions is determined to be unlawful, void, or unenforceable, that provision or part of 
the provision is deemed severable from these Terms and Conditions and does not affect the validity and enforceability of any remaining provisions.

There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms and Conditions or use of the Site. 
You agree that these Terms and Conditions will not be construed against us by virtue of having drafted them.

You hereby waive any and all defenses you may have based on the electronic form of these Terms and Conditions and the lack of signing by the parties 
hereto to execute these Terms and Conditions.

</p>
<h5>CONTACT US</h5>
<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">

In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at: support@dwdigest.com.
                </p>

              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1510222755157-fc26750f1199?ixid=MXwxMjA3fDB8MXxzZWFyY2h8NDh8fG51bWJlcnN8ZW58MHx8fA&ixlib=rb-1.2.1&dpr=1&auto=format&fit=crop&w=500&q=60"
                    className="w-full align-middle rounded-t-lg"
                  />
                </div>
              </div>

            </div>
          </div>
        </section>


      </main>
      <Footer />
    </>
  );
}
