import React from "react";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";

export default function Privacy() {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <a href="/landing">
                  <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <i className="fas fa-home text-xl"></i>
                  </div>
                </a>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Privacy Policy
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
Last updated: February 22nd, 2021
                </p>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
nextCoder ("us", "we", or "our") operates the DW Digest enterprise data warehouse (the "System") which provides data warehouse and analytics serviceds (the “Service”) for the Property Management Industry. This page informs you of our policies regarding the collection, use and disclosure of information we receive from users of the System.
                </p>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
We use your Personal Information only for providing and improving the System. By using the System, you agree to the collection and use of information in accordance with this policy.
                </p>
                <h4 className="text-3xl mb-2 font-semibold leading-normal">
Information Collection And Use
                </h4>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
While using our System, we may ask you to provide us with certain personally identifiable information that is necessary for you to use the System. It can also be utilized to contact or identify data which belong to you. Personally identifiable information may include, but is not limited to your name and work-related email and work-related phone number ("Personal Information").
                </p>
                <h4 className="text-3xl mb-2 font-semibold leading-normal">
                 Log Data
                </h4>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
Like many system operators, we collect information that your browser sends whenever you visit our System ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our System that you visit, the time and date of your visit, the time spent on those pages and other statistics.
                </p>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
In addition, we may use third party services such as Google Analytics that collect, monitor and analyze the collected data for the purposes of improving the System.
                </p>
                <h4 className="text-3xl mb-2 font-semibold leading-normal">
                Communications
                </h4>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                We may use your Personal Information to contact you with technical support, also other information that can help you take advantage of new features provided by the System and other related products.
                </p>
                <h4 className="text-3xl mb-2 font-semibold leading-normal">
                Cookies
                </h4>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.
                </p>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our System.
                </p>
                <h4 className="text-3xl mb-2 font-semibold leading-normal">
                Security
                </h4>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
The security of your Personal Information is important to us. We strive to use industry-best-practices and acceptable means to protect your Personal Information.
                </p>
                <h4 className="text-3xl mb-2 font-semibold leading-normal">
                Changes To This Privacy Policy
                </h4>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
This Privacy Policy is effective as of (add date) and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
                </p>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
                </p>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.
                </p>
                <h4 className="text-3xl mb-2 font-semibold leading-normal">
                Contact Us
                </h4>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
If you have any questions about this Privacy Policy, please contact us at support@dwdigest.com.
                </p>

              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1510222755157-fc26750f1199?ixid=MXwxMjA3fDB8MXxzZWFyY2h8NDh8fG51bWJlcnN8ZW58MHx8fA&ixlib=rb-1.2.1&dpr=1&auto=format&fit=crop&w=500&q=60"
                    className="w-full align-middle rounded-t-lg"
                  />
                </div>
              </div>

            </div>
          </div>
        </section>


      </main>
      <Footer />
    </>
  );
}
