import React from "react";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";

export default function About() {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <a href="/landing">
                  <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <i className="fas fa-home text-xl"></i>
                  </div>
                </a>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  About Us
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
nextCoder was founded in 2012 in Plano, Texas USA. With the founders' combined Enterprise-level IT experience of 50-years, we set out 
to build the best data warehouse to be used by small and middle -sized companies who are progressive enough to recognize the vital role 
that data analytics play in growing the business.
                </p>

              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1510222755157-fc26750f1199?ixid=MXwxMjA3fDB8MXxzZWFyY2h8NDh8fG51bWJlcnN8ZW58MHx8fA&ixlib=rb-1.2.1&dpr=1&auto=format&fit=crop&w=500&q=60"
                    className="w-full align-middle rounded-t-lg"
                  />
                </div>
              </div>

            </div>
          </div>
        </section>


      </main>
      <Footer />
    </>
  );
}
