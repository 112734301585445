import React from "react";
import { useState } from 'react';
import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";

export default function Calc() {

  const [unitCount, setUnitCount] = useState();
  const [pullMetrics, setPullMetrics] = useState();
  const [pushMetrics, setPushMetrics] = useState();
  const [xtraPulls, setXtraPulls] = useState();
  const [output, setOutput] = useState();

  const handleSubmit= (e) => {
    e.preventDefault();
    let pallet = {
      unit_count: unitCount,
      pull_metrics: pullMetrics,
      push_metrics: pushMetrics,
      xtra_pulls: xtraPulls
    };

    console.log("pallet >>>>", pallet);

    fetch("https://yamatowg.nextcoder.com/dd_pricing_calc", {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
      },
      mode: "cors",
      body: JSON.stringify(pallet)      
    }).then(
      res => res.json())
      .then (res => {
        console.log("res >>>", res);
        let out_str = '';
        for (var p in res) {
          var val = res[p];
          out_str += p + ':' + val + '\n';
        }
        setOutput(out_str);
      }).catch(err => setOutput(err))    
  }  

  return (
    <>
      <Navbar transparent />
      <main>
        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <a href="/landing">
                  <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <i className="fas fa-home text-xl"></i>
                  </div>
                </a>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Price Estimate Calculator
                </h3>
                <div class="mx-5" id="calculator">
    {/* <a href="https://dwdigest.com"><button class="text-white font-bold bg-blue-700 hover:bg-blue-800 py-2 px-4 rounded">
      Back to DWDigest.com</button></a>
    <h2 class="text-2xl font-bold mt-8 mb-5">DW Digest Cost Calculator</h2> */}
    <form onSubmit={e => {handleSubmit(e)}}>
    <label>How many managed units?</label>
    <input class="border py-2 px-3 text-grey-darkest params" type="text" id="units_count"
      value={unitCount}
      onChange={e => setUnitCount(e.target.value)}/><br/>
    <label>How many PULLED metrics total?</label>
    <input class="border py-2 px-3 text-grey-darkest params" type="text" id="pull_metrics_count"
      value={pullMetrics}
      onChange={e => setPullMetrics(e.target.value)}/><br/>
    <label>How many PUSHED metrics total?</label>
    <input class="border py-2 px-3 text-grey-darkest params" type="text" id="push_metrics_count"
      value={pushMetrics}
      onChange={e => setPushMetrics(e.target.value)}/><br/>
    <label>How many additional data pull per day? (2 pulls are included)</label>
    <input class="border py-2 px-3 text-grey-darkest params" type="text" id="add_pull_count"
      value={xtraPulls}
      onChange={e => setXtraPulls(e.target.value)}/><br/>
    <button class="mt-5 text-white font-bold bg-blue-700 hover:bg-blue-800 py-2 px-4 rounded"
      type="submit">Calculate Cost</button>
    </form>      
    <div class="text-lg mt-5" style={{whiteSpace : 'pre'}} id="output">{output}</div>
    </div>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1510222755157-fc26750f1199?ixid=MXwxMjA3fDB8MXxzZWFyY2h8NDh8fG51bWJlcnN8ZW58MHx8fA&ixlib=rb-1.2.1&dpr=1&auto=format&fit=crop&w=500&q=60"
                    className="w-full align-middle rounded-t-lg"
                  />
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>
      <Footer />
    </>
  );
}
